import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { DeleteRule, getRulesList, ApplyRuleEngline, updateRule } from 'store/thunk/userThunk';
import { useAppSelector, useAppDispatch } from 'store';
import MainCard from 'ui-component/cards/MainCard';
import MenuItemTable from 'ui-component/menuItem/MenuItemTable';
import DeleteModalDialog from 'ui-component/DeleteModal';
import { Box, Typography, Tooltip, Button, TableCell, FormControlLabel } from '@mui/material';
import { DeleteOutline, Visibility } from '@mui/icons-material';
import { PageValue } from 'store/constant';
import __ from 'underscore';
import moment from 'moment';
import { CustomSwitch } from 'ui-component/customSwitch';

const RuleEngine = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { rulesList, commonAdvertiser } = useAppSelector((state) => state?.userDetailsSlice);
  const [newData, setNewData] = useState();
  const [appliedSortTing, setAppliedSortTing] = useState();
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteData, setDeleteData] = useState();
  const [rowPerPage, setRowPerPage] = useState(25);
  const [page, setPage] = useState(0);
  const [selectedArray, setSelectedArray] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  console.log('selectedArray ', selectedArray);

  const getRulesWithPagination = (page, rowPerPage, changedColumn = '', direction = '', profileIds = null) => {
    dispatch(
      getRulesList({
        page,
        rowPerPage,
        changedColumn,
        direction,
        profileIds: profileIds?.length > 0 ? profileIds?.toString() : ''
      })
    );
  };

  const toggleDeleteModal = (data) => {
    setOpenDelete(!openDelete);
    setDeleteData(data);
    // setInlineEdit({ delete: true });
  };

  const confirmDelete = async () => {
    await dispatch(DeleteRule({ ruleIds: [deleteData] })).then((res) => {
      if (res.payload.status == 204 || res.payload.status == 200) {
        setSelectedArray([]);
        setSelectedRows([]);
        setOpenDelete(false);
        getRulesWithPagination(
          page,
          rowPerPage,
          appliedSortTing?.changedColumnSort,
          appliedSortTing?.directionSort,
          commonAdvertiser
        );
      }
    });
  };

  useEffect(() => {
    console.log('commonAdvertiser ', commonAdvertiser);
    getRulesWithPagination(page, rowPerPage, '', '', commonAdvertiser);
  }, [commonAdvertiser]);

  const handleCustomTableHead = ({
    columnMeta,
    handleToggleColumn,
    sortOrder,
    columnName,
    columnLabel_1,
    columnLabel_2 = '',
    customCss = {},
    isLabelAlignRight = false
  }) => {
    return (
      <TableCell
        sx={{
          position: 'sticky',
          top: '0px',
          zIndex: 1,
          cursor: columnMeta?.sort ? 'pointer' : 'text',
          ...customCss
        }}
        onClick={columnMeta?.sort ? () => handleToggleColumn(columnMeta?.index) : undefined}
        key={columnMeta?.index}
      >
        <Box display="flex" alignItems="center" justifyContent="flex-start" gap={'5px'}>
          <Box
            display="flex"
            alignItems={isLabelAlignRight ? 'flex-end' : 'flex-start'}
            flexDirection="column"
            justifyContent="flex-start"
          >
            <Box className="clr3 fw700 ffInter">{columnLabel_1}</Box>
            {columnLabel_2 && <Box className="clr3 fw700 ffInter">{columnLabel_2}</Box>}
          </Box>
          {!__.isEmpty(sortOrder) && (
            <Box>
              {sortOrder?.direction === 'asc' && sortOrder?.name === columnName ? <img src={upIcon} alt="icon" /> : null}
              {sortOrder?.direction === 'desc' && sortOrder?.name === columnName ? <img src={downIcon} alt="icon" /> : null}
            </Box>
          )}
        </Box>
      </TableCell>
    );
  };

  const RunTheRule = (id) => {
    if (id) {
      dispatch(ApplyRuleEngline({ _request: { rule_id: id } }));
    }
  };

  const handleRuleStatus = (e, id) => {
    const _request = {
      updateRequest: { is_active: e.target.checked },
      getRuleRequest: {
        page,
        rowPerPage,
        changedColumn: appliedSortTing?.changedColumnSort,
        direction: appliedSortTing?.directionSort
      }
    };
    dispatch(updateRule({ _request, RuleId: id }));
  };

  const columns = [
    {
      name: 'name',
      label: 'Rule name',
      options: {
        filter: true,
        sort: false,
        viewColumns: false,
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          const customCss = { zIndex: 101, left: '40px' };
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'name',
            columnLabel_1: 'Rule Name',
            customCss
          });
        },
        customBodyRender: (value, rowData) => {
          const values = rulesList?.results?.[rowData?.rowIndex];

          return (
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography>{value?.length > 200 ? `${value.substring(0, 35)}...` : value}</Typography>

              <Tooltip color="primary" title={<pre>{JSON.stringify(values?.rule_json, null, 2)}</pre>} arrow placement="right">
                <Visibility sx={{ cursor: 'pointer' }} />
              </Tooltip>
            </Box>
          );
        },

        setCellProps: () => ({
          className: 'clr3',
          style: {
            whiteSpace: 'nowrap',
            position: 'sticky',
            left: 40,
            minWidth: '380px',
            background: 'white',
            zIndex: 100
          }
        }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: 'nowrap',
            position: 'sticky',
            left: 40,
            minWidth: '380px',
            background: 'white',
            zIndex: 101
          }
        })
      }
    },
    {
      name: 'module_name',
      label: 'Module name',
      options: {
        filter: true,
        sort: false,
        setCellProps: () => ({
          className: 'clr3'
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'module_name',
            columnLabel_1: 'Module name'
          });
        },
        customBodyRender: (value) => {
          return value || '-';
        }
      }
    },
    {
      name: 'object_ids',
      label: 'Campaign name',
      options: {
        filter: true,
        sort: false,
        setCellProps: () => ({
          className: 'clr3'
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'object_ids',
            columnLabel_1: 'Campaign name'
          });
        },
        customBodyRender: (value) => {
          return value?.length > 0 ? value[0]?.name : '-';
        }
      }
    },
    {
      name: 'last_executed',
      label: 'Last Executed',
      options: {
        filter: true,
        sort: false,
        setCellProps: () => ({
          className: 'clr3'
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'last_executed',
            columnLabel_1: 'Last Executed'
          });
        },
        customBodyRender: (value) => {
          return value ? moment(value).format('yyyy-MM-DD HH:mm:ss') : '';
        }
      }
    },
    {
      name: 'created_at',
      label: 'Create Date',
      options: {
        filter: true,
        sort: false,
        setCellProps: () => ({
          className: 'clr3'
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'created_at',
            columnLabel_1: 'Create Date'
          });
        },
        customBodyRender: (value) => {
          return value ? moment(value).format('yyyy-MM-DD HH:mm:ss') : '';
        }
      }
    },
    {
      name: 'description',
      label: 'Description',
      options: {
        filter: true,
        sort: false,
        setCellProps: () => ({
          className: 'clr3'
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'description',
            columnLabel_1: 'Description'
          });
        },
        customBodyRender: (value) => {
          return (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <>
                {value?.length > 35 ? (
                  <Tooltip
                    placement="top"
                    sx={{ fontSize: '10px' }}
                    title={
                      <Typography variant="body1" sx={{ fontSize: 16 }}>
                        {value}
                      </Typography>
                    }
                    arrow
                  >
                    {value.substring(0, 35)}...
                  </Tooltip>
                ) : (
                  value
                )}
              </>
            </Box>
          );
        }
      }
    },
    {
      name: 'is_active',
      label: 'Status',
      options: {
        filter: true,
        sort: false,
        setCellProps: () => ({
          className: 'clr3'
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'is_active',
            columnLabel_1: 'Status'
          });
        },
        customBodyRender: (value, rowData) => {
          const values = rulesList?.results?.[rowData?.rowIndex];

          return (
            <FormControlLabel
              control={
                <CustomSwitch
                  sx={{ marginRight: '6px' }}
                  color="secondary"
                  checked={values?.is_active}
                  onChange={(e) => handleRuleStatus(e, values.id)}
                />
              }
              labelPlacement="end"
            />
            // <Typography>
            //   <Box sx={{ display: 'flex', alignItems: 'center' }}>
            //     <Circle sx={{ fontSize: 'x-small', color: value ? '#28c76f' : '#bababb' }} />
            //     &nbsp;
            //     {value ? 'Active' : 'Inactive'}
            //   </Box>
            // </Typography>
          );
        }
      }
    },
    {
      name: 'id',
      label: 'Action',
      options: {
        filter: true,
        sort: false,
        setCellProps: () => ({
          className: 'clr3'
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return handleCustomTableHead({
            columnMeta,
            handleToggleColumn,
            sortOrder,
            columnName: 'id',
            columnLabel_1: 'Action'
          });
        },
        customBodyRender: (value) => {
          return (
            <Box display="flex" alignItems="center" gap={0.5}>
              <Tooltip title="Delete" arrow>
                <DeleteOutline sx={{ cursor: 'pointer' }} onClick={() => toggleDeleteModal(value)} />
              </Tooltip>
              <Button variant="text" onClick={() => RunTheRule(value)}>
                Run Rule
              </Button>
            </Box>
          );
        }
      }
    }
  ];

  useEffect(() => {
    if (rulesList?.results) {
      setNewData(rulesList?.results);
    }
  }, [rulesList]);

  const options = {
    search: false,
    filter: false,
    print: false,
    jumpToPage: false,
    responsive: 'standard',
    fixedHeader: true,
    filterType: 'checkbox',
    fixedSelectColumn: true,
    selectToolbarPlacement: 'none',
    tableBodyHeight: '550px',
    download: false,
    serverSide: true,
    page: page,
    count: rulesList?.count || 0,
    viewColumns: false,
    selectableRowsHeader: true,
    selectableRowsHideCheckboxes: false,
    rowsPerPageOptions: PageValue,
    rowsPerPage: rowPerPage,
    pagination: true,
    rowsSelected: selectedRows,
    customToolbar: () => (
      <Button
        variant="contained"
        onClick={() => {
          navigate('/ppc-rules/add-rule');
        }}
      >
        Add Rule
      </Button>
    ),
    onRowSelectionChange: (rowsSelectedData, allRows, rowsSelected) => {
      const selectedIds = rowsSelected.map((selectedIndex) => rulesList?.results?.[selectedIndex]?.id);
      //   const selectedObj = rowsSelected.map((selectedIndex) => rulesList?.results?.[selectedIndex]);
      //   setSelectedObj(selectedObj);
      setSelectedArray(selectedIds);
      setSelectedRows(allRows.map((row) => row.dataIndex));
    },
    onChangeRowsPerPage: (row) => {
      setRowPerPage(row);
      getRulesWithPagination(page, row, appliedSortTing?.changedColumnSort, appliedSortTing?.directionSort, commonAdvertiser);
    },
    onChangePage: (page) => {
      setPage(page);
      getRulesWithPagination(
        page,
        rowPerPage,
        appliedSortTing?.changedColumnSort,
        appliedSortTing?.directionSort,
        commonAdvertiser
      );
    },

    onColumnSortChange: (changedColumn, direction) => {
      setPage(0);
      const payload = {
        changedColumnSort: changedColumn,
        directionSort: direction
      };
      setAppliedSortTing(payload);
      getRulesWithPagination(page, rowPerPage, changedColumn, direction);
    }
  };

  return (
    <MainCard title="PPC Rules">
      <MenuItemTable className="custom-budget-table" data={newData} columns={columns} options={options} />
      <DeleteModalDialog
        item="Rule"
        cancelCallback={() => setOpenDelete(!openDelete)}
        deleteCallback={confirmDelete}
        open={openDelete}
      />
    </MainCard>
  );
};

export default RuleEngine;
