import {
  Autocomplete,
  Box,
  TextField,
  // Typography,
  // List,
  // ListItem,
  // ListItemText,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';
import * as React from 'react';
import { useState, useMemo, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { setCampaignDetails } from 'store/slices/userDetailsSlice';
import { CheckExistingRule, getCampaignDetailsRules, getRuleVariables } from 'store/thunk/userThunk';

import MainCard from 'ui-component/cards/MainCard';

export default function ModuleDetails(props) {
  const { paramData, setParams, showError } = props;
  const dispatch = useAppDispatch();
  const { commonDateRange, campaignDetails, commonAdvertiser } = useAppSelector((state) => state?.userDetailsSlice);

  const [data, setData] = useState({
    campaignsList: []
  });

  useEffect(() => {
    if (campaignDetails?.results?.length > 0) {
      setData({ ...data, campaignsList: campaignDetails?.results });
    }
  }, [campaignDetails?.results]);

  useEffect(() => {
    if (paramData?.moduleName) {
      dispatch(getRuleVariables({ moduleName: paramData?.moduleName }));
    }
  }, []);

  useMemo(() => {
    if (commonAdvertiser?.length > 0) {
      dispatch(getCampaignDetailsRules({ page: 0, rowPerPage: 1000, advertiserFilter: commonAdvertiser, commonDateRange }));
    } else {
      setParams({ ...paramData, profile: null, selectedCampaign: null });
    }
  }, [commonAdvertiser]);

  useMemo(() => {
    if (paramData?.selectedCampaign?.id) {
      setData({ ...data, campaignData: paramData?.selectedCampaign });
    } else {
      setData({ ...data, campaignDetails: null });
      dispatch(setCampaignDetails(null));
    }
  }, [paramData?.selectedCampaign]);

  // const CampaignDetails = (data) => {
  //   if (data?.id) {
  //     return (
  //       <Box sx={{ px: 4 }}>
  //         <Typography variant="h4" gutterBottom>
  //           Campaign Details
  //         </Typography>
  //         <Grid container spacing={4}>
  //           {/* Left Column */}
  //           <Grid item xs={12} sm={6}>
  //             <List>
  //               <ListItem>
  //                 <ListItemText primary="Online Ad SKU ROAS" secondary={data?.roas || 'N/A'} />
  //               </ListItem>
  //               <ListItem>
  //                 <ListItemText primary="Online Ad SKU ACOS" secondary={data?.acos || 'N/A'} />
  //               </ListItem>
  //               <ListItem>
  //                 <ListItemText primary="Online Sale Units" secondary={data?.units_sold || 'N/A'} />
  //               </ListItem>
  //               <ListItem>
  //                 <ListItemText primary="Clicks" secondary={data?.num_ads_clicks || 'N/A'} />
  //               </ListItem>
  //               <ListItem>
  //                 <ListItemText primary="CTR" secondary={data?.ctr || 'N/A'} />
  //               </ListItem>
  //               <ListItem>
  //                 <ListItemText primary="CVR (Orders)" secondary={data?.cvr_orders || 'N/A'} />
  //               </ListItem>
  //               <ListItem>
  //                 <ListItemText primary="CVR (Units)" secondary={data?.cvr_units || 'N/A'} />
  //               </ListItem>
  //               <ListItem>
  //                 <ListItemText primary="NTB Sales(%)" secondary={data?.percentage_new_brand_sales || 'N/A'} />
  //               </ListItem>
  //             </List>
  //           </Grid>
  //         </Grid>
  //       </Box>
  //     );
  //   }
  //   return null;
  // };

  const handleChange = (event) => {
    setParams({ ...paramData, moduleName: event.target.value });
    dispatch(getRuleVariables({ moduleName: event.target.value }));
  };

  const handleRuleBlur = (value) => {
    if (value?.length) {
      dispatch(CheckExistingRule({ _request: { rule_name: value } }));
    }
  };

  return (
    <MainCard>
      <Grid container spacing={2}>
        <Grid item md={6}>
          <Box mb={2}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Module name</InputLabel>
              <Select
                // sx={{ maxWidth: '400px' }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={paramData?.moduleName || ''}
                label="Module Name"
                onChange={handleChange}
              >
                <MenuItem value="Campaign">Campaigns</MenuItem>
                {/* <MenuItem value="Keyword">keywords</MenuItem>
                <MenuItem value="Item">Items</MenuItem> */}
              </Select>
            </FormControl>
          </Box>
          <Box mb={2}>
            <TextField
              fullWidth
              // sx={{ maxWidth: '400px' }}
              id="outlined-basic"
              label="Rule Name"
              variant="outlined"
              onChange={(e) => setParams({ ...paramData, ruleName: e.target.value })}
              value={paramData?.ruleName || ''}
              onBlur={(e) => handleRuleBlur(e.target.value)}
              error={showError && paramData?.moduleName?.length}
              helperText={
                showError && paramData?.moduleName?.length ? 'The rule name is already taken. Please choose a different one.' : ''
              }
            />
          </Box>
          <Box mb={2}>
            <Autocomplete
              freeSolo
              fullWidth
              id="free-solo-2-demo"
              name="Campaign"
              className="custom-auto"
              value={paramData?.selectedCampaign || {}}
              // sx={{ maxWidth: '400px' }}
              size={'small'}
              options={data?.campaignsList || []}
              getOptionLabel={(option) => {
                return option?.name || '';
              }}
              onChange={(event, newValue) => {
                setParams({ ...paramData, selectedCampaign: newValue });
              }}
              renderInput={(params) => <TextField color="secondary" sx={{ width: 'auto' }} {...params} label="Campaign Name" />}
            />
          </Box>
          <Box mb={2}>
            <TextField
              multiline
              fullWidth
              // sx={{ maxWidth: '400px' }}
              rows={3}
              id="outlined-basic"
              label="Rule Description"
              variant="outlined"
              onChange={(e) => setParams({ ...paramData, description: e.target.value })}
              value={paramData?.description || ''}
            />
          </Box>
          {/* <Box mb={1}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Frequency</InputLabel>
              <Select
                // sx={{ maxWidth: '400px' }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={paramData?.frequency || ''}
                label="Frequency"
                onChange={(e) => setParams({ ...paramData, frequency: e.target.value })}
              >
                <MenuItem value={1}>Every 1 min</MenuItem>
                <MenuItem value={3}>Every 3 min</MenuItem>
                <MenuItem value={5}>Every 5 min</MenuItem>
                <MenuItem value={30}>Every 30 min</MenuItem>
                <MenuItem value={60}>Every 1 hour</MenuItem>
                <MenuItem value={120}>Every 2 hour</MenuItem>
                <MenuItem value={1440}>Everyday</MenuItem>
                <MenuItem value={10080}>Every week</MenuItem>
                <MenuItem value={43800}>Every month</MenuItem>
              </Select>
            </FormControl>
          </Box> */}
        </Grid>
        {/* <Grid item md={6}>
          {CampaignDetails(paramData?.selectedCampaign)}
        </Grid> */}
      </Grid>
    </MainCard>
  );
}
